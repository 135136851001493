.queue-section {
  display: flex;
  align-items: center;
  justify-content: space-around;
  text-align: center;
}

.queue-position-wrapper {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.queue-position-circle {
  min-width: 100px;
  height: 100px;
  padding: 10px; // Add some padding to avoid touching the borders
  border-radius: 50%;
  background-color: #e0e0e0;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 32px;
  font-weight: bold;
  overflow: hidden; // Prevent content from spilling out
}

// .queue-position-circle span {
//   margin: 0 auto;
//   display: block;
//   padding: 0 10px; // Allow some padding on the sides
//   white-space: nowrap; // Prevent breaking into multiple lines
// }
.share-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.social-icons {
  display: flex;
  gap: 10px; /* Adjust the space between icons as needed */
  font-size: 24px; /* Adjust size as needed */
  margin-top: 10px; /* Space from the heading */
}

.social-icons span {
  cursor: pointer; /* Changes the cursor to indicate it's clickable */
  padding: 5px;
  border-radius: 50%; /* Makes the icon container circular */
  background: #e0e0e0; /* Just an example color */
}

.text-success{
  color: $chat-green;
}

.join-waitlist-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  padding: 50px;
  background: #291b49; /* Adjust the background color as needed */
}

.form-section, .info-section {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0 20px;
}

.join-form {
  max-width: 300px;
  width: 100%;
}

.join-form input[type="text"],
.join-form input[type="email"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.join-form input[type="submit"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: none;
  border-radius: 4px;
  background-color: #6a5acd; /* Adjust submit button color as needed */
  color: white;
  cursor: pointer;
}

.info-section h2 {
  color: white;
  margin-bottom: 15px;
}

.info-section p {
  color: white;
  text-align: center;
  margin-bottom: 15px;
}

.info-section button {
  padding: 10px 20px;
  background-color: transparent;
  color: white;
  border: 1px solid white;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: 768px) {
  .join-waitlist-container {
    flex-direction: column;
  }

  .form-section, .info-section {
    margin: 10px 0;
  }
}

/* Add this to your CSS file */
.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  width: 36px;
  height: 36px;
  border-radius: 50%;
  border-left-color: #6c6d6d;

  animation: spin 1s ease infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

* {
  font-family: $font-family;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

h1 {
  //   color: $primary;
  font-size: 22px;
  line-height: 30px;
  font-weight: 400;
  @media screen and (min-width: 768px) {
    font-size: 35px;
    line-height: 45px;
  }
  @media screen and (min-width: 992px) {
    font-size: 45px;
    line-height: 56px;
  }
}

h2 {
  font-family: $font-family;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  //   color: $primary;
  @media screen and (min-width: 768px) {
    font-size: 25px;
    line-height: 35px;
  }
  @media screen and (min-width: 992px) {
    font-size: 30px;
    line-height: 38px;
  }
}

h3 {
  font-weight: 400;
  font-size: 22px;
  line-height: 40px;
  //   color: $primary;
  @media screen and (min-width: 768px) {
    font-size: 32px;
    line-height: 45px;
  }
  @media screen and (min-width: 992px) {
    font-size: 40px;
    line-height: 50px;
  }
}

p {
  //   color: $light-sky-blue;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  margin-bottom: 1rem;
  white-space: pre-line;
  @media screen and (min-width: 768px) {
    font-size: 18px;
    line-height: 30px;
  }
}

.paragraph-small {
  font-size: 12px;
  line-height: 15px;
  color: $black;
}

.paragraph-x-small {
  font-weight: 300;
  font-size: 10px;
  line-height: 15px;
  color: $black;
}

a {
  color: $link-color;
}

.pink-text {
  color: $secondary !important;
}

.teal-text {
  color: $teal-blue !important;
}

.primary-text {
  color: $primary !important;
}

.gray-text {
  color: $label-color;
}

.z-index--1 {
  z-index: -1;
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.fw-300 {
  font-weight: 300;
}

.fw-400 {
  font-weight: 400;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.box-shadow {
  box-shadow: 0px 2.25px 2.25px rgba(0, 0, 0, 0.25);
}

.error-border {
  border-color: red !important;
}

.cookie-banner {
  position: fixed;
  bottom: 5px;
  width: fit-content;
  left: 0;
  right: 0;
  margin: 12px auto;
  padding: 5px;
  background-color: #323232;
  color: aliceblue;
  border-radius: 12px;
  z-index: 1;
}

.chloee-container {
  background: linear-gradient(90deg, #f7f0fe, #e7e5fc, #e3f4fc);
}

.primary-btn {
  padding: 12px;
  font-size: 15px;
  cursor: pointer;
  max-width: 12rem;
  text-align: center;
  border-radius: 12px;
  color: $white !important;
  background: linear-gradient(90deg, #a039f8, #5e3bed, #039fe7);
}

.secondary-btn {
  padding: 12px;
  font-size: 15px;
  cursor: pointer;
  max-width: 12rem;
  text-align: center;
  border-radius: 12px;
  color: $black !important;
  background-color: white !important;
}

.secondary-btn:hover {
  color: $white !important;
  background: linear-gradient(90deg, #a039f8, #5e3bed, #039fe7);
}

.nav-brand-text {
  font-size: 18px;
}

.nav-text {
  font-size: 15px;
  margin-inline: 1rem;
}

.gradient-text {
  position: relative;
  color: transparent;
  background: linear-gradient(90deg, #a039f8, #5e3bed, #039fe7);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* For WebKit browsers */
}

.hero-pre-heading {
  text-transform: uppercase;
}

.hero-heading {
  font-size: 26px;
  color: #333333;
  line-height: 40px;
  @media screen and (min-width: 575px) {
    font-size: 35px;
    line-height: 40px;
  }
  @media screen and (min-width: 992px) {
    font-size: 46px;
    line-height: 54px;
  }
}

.hero-sub-heading {
  font-size: 16px;
  color: #333333;
}

.hero-img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.chloee-logo {
  width: 65px;
  margin-top: -8px;
  cursor: pointer;
  @media screen and (min-width: 768px) {
    width: 85px;
  }
}

.social-icons-header {
  font-size: 24px !important;
}

// footer section
.footer-container {
  background-color: #d9d9d9;
}

.onyxtec-logo {
  width: 20px;
  height: 20px;
}
// footer section
